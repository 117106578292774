import React from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import Logo from "../components/logo.svg"
import { mergeClasses } from "@material-ui/styles"
import { makeStyles } from "@material-ui/core/styles"
import Footer from "../components/footer"

const styles = makeStyles(theme => ({
  titleText: {
    textAlign: "center",
  },
}))

export default function Disclaimer(props) {
  const classes = styles()
  return (
    <Layout>
      <SEO />
      <Grid container>
        <h1 className={classes.titleText}>Disclaimer</h1>
        <p>
          Explore Personal Finance does the best we can to provide you with
          accurate information about personal finance. We strive to write about
          topics we feel competent in, and skip over those that we do not.
        </p>
        <p>
          Our writings are for educational and informational purposes only and
          may not apply to every situation. Do not use this website as your only
          form of financial information, and consult a professional if you feel
          you need financial advice for your specific situation.
        </p>
        <p></p>
      </Grid>
      <Footer />
    </Layout>
  )
}
